import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceEmpty'
})
export class ReplaceEmptyPipe implements PipeTransform {

  transform(value: any): string {
    return value?.length > 0 ? value : '-';
  }

}
