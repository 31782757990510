import { LanguageVersion } from '@data/common/LanguageVersion';
import { EnvironmentType } from '@data/common/EnvironmentType';

export const environment = {
  name: EnvironmentType.DEV,
  production: false,
  apiUrl: 'https://api.zajac-dev.apzumi.dev/api',
  defaultLanguage: LanguageVersion.PL,
  cognito: {
    userPoolId: 'eu-central-1_Ml3RWf0Wc',
    userPoolWebClientId: '36afb516a4l4pdg5j268keou2l',
    region: 'eu-central-1'
  }
};
