import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { TranslateUtil } from '@shared/util/translate.util';

@Injectable({
  providedIn: 'root'
})
export class NotificationUtil {

  constructor(private toastrService: ToastrService, private translateUtil: TranslateUtil) {
  }

  public success(text?: string, params: any = {}, title: string = 'COMMON.SUCCESS') {
    const translations = this.translateUtil.translate([ text, title ], params);
    this.toastrService.success(translations[text], translations[title]);
  }

  public info(text: string, params: any = {}, title: string = 'COMMON.INFO') {
    const translations = this.translateUtil.translate([ text, title ], params);
    this.toastrService.info(translations[text], translations[title]);
  }

  public warning(text: string, params: any = {}, title: string = 'COMMON.WARNING') {
    const translations = this.translateUtil.translate([ text, title ], params);
    this.toastrService.warning(translations[text], translations[title]);
  }

  public error(text: string, params: any = {}, title: string = 'COMMON.ERROR') {
    const translations = this.translateUtil.translate([ text, title ], params);
    this.toastrService.error(translations[text], translations[title]);
  }


  public removeAll() {
    this.toastrService.toasts.forEach((toast: ActiveToast<any>) => {
      this.toastrService.remove(toast.toastId);
    });
  }
}
