import { Component, TemplateRef } from '@angular/core';
import { BasicModal } from '@shared/extender/basic-modal';

export interface ConfirmationModal {
  title: string,

  description: string,

  data?: unknown,

  icon?: string,

  iconClass?: string,

  confirmButtonText?: string,

  cancelButtonText?: string,

  showCancelButton?: boolean,

  hardConfirm?: boolean
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: [ './confirmation-modal.component.scss' ]
})
export class ConfirmationModalComponent extends BasicModal<boolean> implements ConfirmationModal {

  public title: string = 'COMMON.CONFIRM_TITLE';

  public titleTemplate: TemplateRef<any>;

  public description: string = 'COMMON.CONFIRM_DESCRIPTION';

  public descriptionTemplate: TemplateRef<any>;

  public confirmButtonText: string = 'COMMON.CONFIRM';

  public cancelButtonText: string = 'COMMON.CANCEL';

  public showCancelButton: boolean = true;

  public icon?: string;

  public iconClass?: string;

  public data: any = {};

  public hardConfirm: boolean = false;

  public hardConfirmText: string = '';

  public ok(val: boolean) {
    if (!this.hardConfirm) {
      super.ok(val)
    }  else if(this.hardConfirmText === 'Potwierdzam') {
      super.ok(val)
    }
  }
}
