<div id="sidebar">
  <div class="nav">
    <div class="logo mb-3">
      <app-logo></app-logo>
    </div>

    <ng-container *ngFor="let sideBarItem of menuItems$ | async">
      <button type="button" class="btn btn-navigate"
              [routerLink]="sideBarItem.link"
              [routerLinkActiveOptions]="{exact: sideBarItem.routerLinkExact}"
              routerLinkActive="active">
        <div class="d-flex align-items-center gap-2">
          <svg-icon *ngIf="sideBarItem.icon" [src]="'assets/image/icon/' + sideBarItem.icon + '.svg'" ></svg-icon>
          <span
            class="label d-inline d-md-none d-lg-inline">{{ 'COMPONENT.SIDE_MENU.' + sideBarItem.title | translate }}</span>
        </div>
      </button>
    </ng-container>
  </div>
  <div class="d-flex flex-column gap-4">
    <p class="mb-1">{{(user$ | async)?.email}}</p>
    <button type="button" class="d-flex btn btn-link btn-link-primary btn-lg px-1"
            (click)="logout()">
      <i-tabler name="logout" class="icon"></i-tabler>
      <span class="ms-3">{{ 'COMPONENT.SIDE_MENU.LOGOUT' | translate }}</span>
    </button>
  </div>
<!--  <div class="version">v{{ applicationModel.appVersion }}</div>-->
</div>