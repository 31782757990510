import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoaderModel {
  private loadingMap: Map<string, boolean> = new Map<string, boolean>();

  private loadingMap$: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject(this.loadingMap);

  constructor(private ngZone: NgZone,
              private spinner: NgxSpinnerService) {
    this.startListener();
  }

  private startListener(): void {
    this.loadingMap$
      .pipe(
        delay(0)
      )
      .subscribe((map: Map<string, boolean>) => {
        this.ngZone.run(() => {
          if (map.size > 0) {
            this.spinner.show('main');
          } else {
            this.spinner.hide('main');
          }
        });
      });
  }

  setLoading(loading: boolean, key: string): void {
    if (!key) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading) {
      this.loadingMap.set(key, true);
    } else {
      this.loadingMap.delete(key);
    }

    // Emit updated map
    this.loadingMap$.next(this.loadingMap);
  }
}