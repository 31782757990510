import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ApplicationConfig } from '@config/application.config';

@Injectable({
  providedIn: 'root'
})
export class ApplicationModel {
  public windowSize: BehaviorSubject<number> = new BehaviorSubject<number>(window.innerWidth);

  public windowSize$: Observable<number> = this.windowSize.asObservable();

  public isMobileMode$: Observable<boolean> = this.windowSize.asObservable()
    .pipe(
      map((size: number) => size <= 1024),
      distinctUntilChanged()
    );

  private sideMenuVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public sideMenuVisible$: Observable<boolean> = this.sideMenuVisible.asObservable().pipe(distinctUntilChanged());

  constructor() {
  }

  public get appVersion(): string {
    return ApplicationConfig.version.substring(0, ApplicationConfig.version.lastIndexOf(' '));
  }
}
