import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'include'
})
export class IncludePipe implements PipeTransform {
  transform(value: string[], include: string): boolean {
    return (value || []).includes(include);
  }

}
