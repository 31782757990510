import { TokenDTO } from '@data/auth/TokenDTO';

export namespace AuthActions {
  const prefix: string = '[Auth]';

  export class Login {
    static readonly type = `${ prefix } Log in`;

    constructor(public username: string, public password: string) {

    }
  }

  export class ForgetPassword {
    static readonly type = `${ prefix } Forget password`;

    constructor(public username: string) {

    }
  }

  export class ResendVerificationCode {
    static readonly type = `${ prefix } Resend verification code`;

    constructor() {

    }
  }

  export class ConfirmPassword {
    static readonly type = `${ prefix } Confirm password`;

    constructor(public verificationCode: string, public newPassword: string) {

    }
  }

  export class SetPassword {
    static readonly type = `${ prefix } Set password`;

    constructor(public newPassword: string) {

    }
  }

  export class GetCurrentLogged {
    static readonly type = `${ prefix } Get current logged`;

    constructor() {

    }
  }

  export class SaveToken {
    static readonly type = `${ prefix } Update token`;

    constructor(public token: TokenDTO) {

    }
  }

  export class RefreshToken {
    static readonly type = `${ prefix } Refresh token`;

    constructor() {
    }
  }

  export class Logout {
    static readonly type = `${ prefix } Log out`;

    constructor() {
    }
  }
}