import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(value: string): string {
    const regex = /(\b(https:\/\/|www){1}\S+)/gi;
    const replacedText = value.replace(regex, (url) => {

      // checks if the match is not an attribute value or part of an html link
      if (url.match((/(<\/a>|")/i))) {
        return url;
      } else {
        // adds https://
        return `<a href="${ url.match(/^https?:\/\//i) ? url : 'https://' + url }" target="_blank">${ url }</a>`;
      }
    });

    return replacedText;
  }

}
