import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '@core/layout/component/main-layout/main-layout.component';
import { PreAuthLayoutComponent } from '@core/layout/component/pre-auth-layout/pre-auth-layout.component';
import { authGuard } from '@shared/guard/auth.guard';

const routes: Routes = [
  {
    path: 'auth', component: PreAuthLayoutComponent,
    loadChildren: () => import('app/view/auth/auth-page.module').then(m => m.AuthPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('app/view/error/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: '', component: MainLayoutComponent, canActivate: [ authGuard ],
    children: [
      {
        path: '',
        loadChildren: () => import('app/view/super-admin/super-admin-role.module').then(m => m.SuperAdminRoleModule)
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
