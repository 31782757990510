<ng-container *ngIf="!isFormError && controlContainer.control?.get(controlName).touched">
  <div class="text-danger" *ngIf="(errors || controlContainer.control?.get(controlName)?.errors) as result">
    <div class="d-flex flex-column">
      <ng-container *ngFor="let error of result | keyvalue">
        <small>{{ (validationMessage[error.key] || error?.value?.message || 'VALIDATION.VALIDATION') | translate:error?.value }}</small>
      </ng-container>
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>


<ng-container *ngIf="isFormError && showErrors">
  <div class="text-danger" *ngIf="(errors || controlContainer?.errors) as result">
    <div class="d-flex flex-column">
      <ng-container *ngFor="let error of result | keyvalue">
        <small *ngIf="observedErrorName === error.key">{{ (formValidationMessage[error.key] || error?.value?.message || 'VALIDATION.VALIDATION') | translate:error?.value }}</small>
      </ng-container>
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>