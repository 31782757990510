import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CastToType',
  pure: true
})
export class CastToTypePipe implements PipeTransform {
  transform<T>(value: any, _type: (new (...args: any[]) => T) | T): T {
    return value as T;
  }
}
