<div class="modal-body d-flex gap-4 flex-column">
  <ng-container [ngTemplateOutlet]="titleTemplate || defaultTitleTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="descriptionTemplate || defaultDescriptionTemplate"></ng-container>
  <div class="form-group mt-4" *ngIf="hardConfirm">
      <textarea type="text" [(ngModel)]="hardConfirmText" class="form-control custom-control-value-element"
                [placeholder]="'COMMON.WRITE_CONFIRM' | translate"
                id="description-input"
                rows="5"></textarea>
  </div>
</div>

<div class="d-flex modal-footer">
  <button type="button"
          class="btn btn-link btn-link-primary btn-lg w-50" *ngIf="showCancelButton"
          (click)="close()">{{ cancelButtonText | translate:data }}
  </button>
  <button type="button"
          class="btn btn-primary btn-lg w-50" [disabled]="hardConfirm && hardConfirmText !== 'Potwierdzam'"
          (click)="ok(true)">{{ confirmButtonText | translate:data }}
  </button>
</div>

<!--Templates-->
<ng-template #defaultTitleTemplate>
  <h5 class="fw-bold" [innerHtml]="title | translate: data"></h5>
</ng-template>

<ng-template #defaultDescriptionTemplate>
  <span [innerHtml]="description | translate: data"></span>
</ng-template>
