import { NavigationExtras, Params } from '@angular/router';

export namespace NavigationActions {
  export class Back {
    static readonly type = '[Navigation] Go back';

    constructor(public path?: any[], public queryParams?: Params | undefined, public extras?: NavigationExtras | undefined) {

    }
  }
}