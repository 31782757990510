import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DifficultyLevel } from '@data/common/DifficultyLevel';

@Component({
  selector: 'app-level-icon',
  template: `
      <div class="d-flex align-items-center">
          <svg-icon *ngIf="difficultyLevel === DifficultyLevel.BEGINNER" src="assets/image/icon/level-one-icon.svg"></svg-icon>
          <svg-icon *ngIf="difficultyLevel === DifficultyLevel.INTERMEDIATE" src="assets/image/icon/level-two-icon.svg"></svg-icon>
          <svg-icon *ngIf="difficultyLevel === DifficultyLevel.ADVANCED" src="assets/image/icon/level-three-icon.svg"></svg-icon>
      </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelIconComponent implements OnInit {
  public readonly DifficultyLevel: typeof DifficultyLevel = DifficultyLevel;

  @Input()
  public difficultyLevel: DifficultyLevel
  constructor() {
  }

  ngOnInit(): void {
  }

}
