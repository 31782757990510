import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDragDropFile]'
})
export class DragDropFileDirective {
  @HostBinding('class.drag-over')
  public dragOver: boolean;

  @Output() draggedFiles: EventEmitter<File[]> = new EventEmitter();

  @HostListener('dragover', [ '$event' ])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = true;
  }

  @HostListener('dragleave', [ '$event' ])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = false;
  }

  @HostListener('drop', [ '$event' ])
  public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    const { files } = evt.dataTransfer;
    const filteredFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const file: File = evt.dataTransfer.files[i];
      filteredFiles.push(file);
    }

    if (filteredFiles.length > 0) {
      this.draggedFiles.emit(filteredFiles);
    }
  }
}