import { FullName } from '../interface/FullName';
import { AccountType } from '@data/auth/AccountType';
import { IdentifiableObject } from '@data/common/IdentifiableObject';

export class AccountDTO extends IdentifiableObject implements FullName {
  public firstName: string;

  public lastName: string;

  public type: AccountType;

  public permissions: string[];

  public email: string;

  public enabled: boolean;
}