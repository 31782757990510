import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '@config/application.config';
import { Constant } from '@common/constant';
import format from 'date-fns/format';

@Injectable()
export class AppTimezoneInterceptor implements HttpInterceptor {
  private timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  private offset: string = format(new Date(), 'XXX');

  constructor() {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO removed authUrl
    if (request.method && ((request.url?.indexOf(ApplicationConfig.apiUrl) > -1))) {
      const timezoneRequest: HttpRequest<any> = request.clone({
        setHeaders: {
          [Constant.HTTP_TIMEZONE_HEADER]: this.timezone,
          [Constant.HTTP_UTC_OFFSET_HEADER]: this.offset
        }
      });

      return next.handle(timezoneRequest);
    } else {
      return next.handle(request);
    }
  }
}
