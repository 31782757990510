import { Component, OnDestroy } from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ApplicationModel } from 'app/model/application.model';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [ './main-layout.component.scss' ]
})
export class MainLayoutComponent implements OnDestroy {
  private collapseMenuBreakpoint: number = 1440;

  public menuCollapse: boolean = window.innerWidth < this.collapseMenuBreakpoint;

  public sideMenuVisible: boolean;

  private subscription: Subscription = new Subscription();

  constructor(private applicationModel: ApplicationModel,
              private router: Router) {
    this.subscribeWindowSize();
    this.subscribeRouterEvents();
    this.subscribeSideMenuVisibility();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // controls menu collapse depending on window width
  private subscribeWindowSize(): void {
    this.subscription.add(
      this.applicationModel.windowSize$
        .pipe(
          map((windowSize) => windowSize < this.collapseMenuBreakpoint ? true : false),
          distinctUntilChanged()
        ).subscribe(
        (value: boolean) => {
          this.menuCollapse = value;
        }
      )
    );
  }

  // manage closing mobile menu when redirect
  private subscribeRouterEvents(): void {
    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe(() => {
        this.menuCollapse = window.innerWidth < this.collapseMenuBreakpoint ? true : false;
      })
    );
  }

  private subscribeSideMenuVisibility(): void {
    this.subscription.add(this.applicationModel.sideMenuVisible$
      .pipe(
        debounceTime(10)
      )
      .subscribe((visible: boolean) => {
        this.sideMenuVisible = visible;
      }));
  }

}
