import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { ApplicationConfig } from '@config/application.config';
import { Constant } from '@common/constant';
import { LoaderModel } from 'app/model/loader.model';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderModel: LoaderModel) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, urlWithParams, headers } = request;

    if (url.startsWith(ApplicationConfig.apiUrl)) {
      if (!headers.has(Constant.HTTP_SILENT_MODE)) {
        this.loaderModel.setLoading(true, urlWithParams);

        return next.handle(request)
          .pipe(
            finalize(() => {
              this.loaderModel.setLoading(false, urlWithParams);
            })
          );
      }

      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
