import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionCompleted, ofActionSuccessful } from '@ngxs/store';
import { Router } from '@angular/router';
import { debounceTime, fromEvent, merge, Subject, takeUntil } from 'rxjs';
import { ApplicationConfig } from '@config/application.config';
import { filter } from 'rxjs/operators';
import { ApplicationModel } from 'app/model/application.model';
import { AuthActions } from '@shared/storage/auth/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private applicationModel: ApplicationModel,
              private router: Router,
              private actions: Actions) {
  }

  ngOnInit() {
    merge(
      this.actions.pipe(
        ofActionSuccessful(AuthActions.Login)
      ),
      this.actions.pipe(
        ofActionSuccessful(AuthActions.SetPassword)
      )
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.router.navigate([ '/' ]));


    this.actions.pipe(
      ofActionCompleted(AuthActions.Logout),
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.router.navigate([ '/auth/login' ]));

    fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event: StorageEvent) => event.key.startsWith(ApplicationConfig.applicationName)),
        debounceTime(300)
      )
      .subscribe(() => window.location.reload());
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // HostListener doesn't work in ApplicationModel, so it's placed in a global component
  @HostListener('window:resize', [ '$event' ])
  onResize() {
    this.applicationModel.windowSize.next(window.innerWidth);
  }
}
