import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[type="password"][appInputPasswordToggle]'
})
export class InputPasswordToggleDirective {
  private _shown = false;

  constructor(private el: ElementRef,
              private readonly renderer2: Renderer2) {
    const parent = this.el.nativeElement.parentNode;
    const spanElement = this.renderer2.createElement('span');

    this.renderer2.addClass(spanElement, 'password-toggle-icon');
    this.renderer2.setStyle(this.el.nativeElement, 'padding-right', '50px');
    this.renderer2.appendChild(parent, spanElement);

    spanElement.addEventListener('click', () => this.toggle(spanElement));
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;

    if (this._shown) {
      this.renderer2.setAttribute(this.el.nativeElement, 'type', 'text');
      this.renderer2.addClass(span, 'password-visible');
    } else {
      this.renderer2.setAttribute(this.el.nativeElement, 'type', 'password');
      this.renderer2.removeClass(span, 'password-visible');
    }
  }
}
