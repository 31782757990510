import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate, RouterDataResolved } from '@ngxs/router-plugin';
import { NavigationActions } from '@shared/storage/navigation/navigation.actions';
import { Location } from '@angular/common';

export interface NavigationStateModel {
  history: string[];

}

@State<NavigationStateModel>({
  name: 'navigation',
  defaults: {
    history: []
  }
})
@Injectable()
export class NavigationState {
  constructor(private location: Location) {

  }

  @Action(RouterDataResolved)
  public routerDataResolved(ctx: StateContext<NavigationStateModel>, action: RouterDataResolved) {
    const state = ctx.getState();
    ctx.patchState({ history: [ ...state.history, action.event.urlAfterRedirects ] });
  }

  @Action(NavigationActions.Back)
  public back(ctx: StateContext<NavigationStateModel>, action: NavigationActions.Back) {
    const state = ctx.getState();

    if (state.history.length > 1) {
      const history = state.history;
      ctx.patchState({ history: [ ...history.slice(0, history.length - 1) ] });
      this.location.back();
    } else {
      ctx.dispatch(new Navigate(action.path || [ '/' ], action.queryParams, {
        ...action.extras
      }));
    }
  }

}