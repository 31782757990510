import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthActions } from '@shared/storage/auth/auth.actions';
import { AuthService } from '@shared/service/auth.service';
import { firstValueFrom, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationUtil } from '@shared/util/notification.util';
import { TokenDTO } from '@data/auth/TokenDTO';
import { AccountDTO } from '@data/auth/AccountDTO';

export interface AuthStateModel {
  username: string;

  token: TokenDTO;

  isLogging: boolean;

  loggedUser: AccountDTO;
}

@State<AuthStateModel>({
  name: 'auth'
})
@Injectable()
export class AuthState {
  constructor(private readonly authService: AuthService,
              private readonly notificationUtil: NotificationUtil) {
  }

  @Action(AuthActions.Login)
  async login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login) {
    try {
      ctx.patchState({ isLogging: true, username: action.username });

      const token: TokenDTO = await firstValueFrom(this.authService.login(action.username, action.password));
      await firstValueFrom(ctx.dispatch(new AuthActions.SaveToken(token)));
    } catch (ex) {
      return Promise.reject(ex);
    } finally {
      ctx.patchState({ isLogging: false });
    }
  }

  @Action(AuthActions.SetPassword)
  async setPassword(ctx: StateContext<AuthStateModel>, action: AuthActions.SetPassword) {
    try {
      const { username } = ctx.getState();

      ctx.patchState({ isLogging: true });

      const token: TokenDTO = await firstValueFrom(this.authService.setPassword(username, action.newPassword));
      await firstValueFrom(ctx.dispatch(new AuthActions.SaveToken(token)));
    } catch (ex) {
      return Promise.reject(ex);
    } finally {
      ctx.patchState({ isLogging: false });
    }
  }

  @Action(AuthActions.ForgetPassword)
  async forgetPassword(ctx: StateContext<AuthStateModel>, action: AuthActions.ForgetPassword) {
    ctx.patchState({ username: action.username });
    return this.authService.forgetPassword(action.username);
  }

  @Action(AuthActions.ResendVerificationCode)
  async resendVerificationCode(ctx: StateContext<AuthStateModel>, action: AuthActions.ResendVerificationCode) {
    const { username } = ctx.getState();

    return this.authService.resendVerificationCode(username);
  }

  @Action(AuthActions.ConfirmPassword)
  async confirmPassword(ctx: StateContext<AuthStateModel>, action: AuthActions.ConfirmPassword) {
    const { username } = ctx.getState();

    return this.authService.confirmPassword(username, action.verificationCode, action.newPassword);
  }

  @Action(AuthActions.RefreshToken)
  async refreshToken(ctx: StateContext<AuthStateModel>) {
    const { token } = ctx.getState();

    return this.authService.refreshToken(token)
      .pipe(
        tap((refreshedToken: TokenDTO) => ctx.dispatch(new AuthActions.SaveToken(refreshedToken))),
        catchError(() => {
          this.notificationUtil.info('ERROR.REFRESH_TOKEN_EXPIRED');
          return ctx.dispatch(new AuthActions.Logout);
        })
      );
  }

  @Action(AuthActions.Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ token: null, loggedUser: null });
  }

  @Action(AuthActions.SaveToken)
  async saveToken(ctx: StateContext<AuthStateModel>, payload: AuthActions.SaveToken) {
    const { token } = payload;

    ctx.patchState({ token: token, isLogging: false });
    await firstValueFrom(ctx.dispatch(new AuthActions.GetCurrentLogged()));
  }

  @Action(AuthActions.GetCurrentLogged)
  getCurrentLogged(ctx: StateContext<AuthStateModel>) {
    return this.authService.getCurrentLoggedUser()
      .pipe(
        tap((loggedUser: AccountDTO) => ctx.patchState({ loggedUser }))
      );
  }
}