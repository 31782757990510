import { Component, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-validation-handler',
  templateUrl: './validation-handler.component.html'
})
export class ValidationHandlerComponent implements OnInit {
  @Input() public errors: ValidationErrors;
  @Input() public isFormError: boolean;
  @Input() public showErrors: boolean;
  @Input() public observedErrorName: string;

  @Input()
  public set validation(value: any) {
    Object.assign(this.validationMessage, value);
  }

  @Input() controlName: string;

  public validationMessage = {
    'required': 'VALIDATION.REQUIRED',
    'pattern': 'VALIDATION.PATTERN',
    'email': 'VALIDATION.EMAIL',
    'phone': 'VALIDATION.PHONE',
    'minlength': 'VALIDATION.CHAR_MIN',
    'invalidJson': 'VALIDATION.INVALID_JSON',
    'passwordsDoNotMatch': 'VALIDATION.PASSWORDS_DO_NOT_MATCH',
    'passwordRequirements': 'VALIDATION.PASSWORD_REQUIREMENTS'
  };

  public formValidationMessage = {
    'atLeastOneEquipmentFieldRequired': 'VALIDATION.AT_LEAST_ONE_EQUIPMENT_REQUIRED',
    'atLeastOneBodyPartFieldRequired': 'VALIDATION.AT_LEAST_ONE_BODY_PART_REQUIRED'
  }

  constructor(@Optional() @Host() @SkipSelf() public controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
  }

}
