import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concat'
})
export class ConcatPipe implements PipeTransform {

  transform(value: any[], field: string, separator: string = ', '): string {
    return value?.map(s => s[field])?.join(separator);
  }

}
