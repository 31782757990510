import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(start: Date, end: Date): number {
    return differenceInMinutes(end, start);
  }

}
