import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateUtil {

  constructor(private translateService: TranslateService) {
  }

  public translate(key: string | Array<string>, params: any = {}): string {
    return this.translateService.instant(key, params);
  }
}
