import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  template: `
    <a [routerLink]="['']">
      <svg-icon src="assets/image/logo.svg"></svg-icon>
    </a>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
