import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Directive({ selector: 'input[type=number][appCurrency]' })
export class InputCurrencyDirective {

  constructor(private el: ElementRef, private ngControl: NgControl, private decimalPipe: DecimalPipe) {
  }

  @HostListener('blur')
  public onInputBlur(): void {
    try {
      const value: any = this.el.nativeElement.value || '';
      const valueParsed: number = value.replace(/[^0-9\.\,]/g, '').replace(/,/g, '.');
      const finalValue = this.decimalPipe.transform(valueParsed, '1.2-2').replace(/,/g, '.').replace(/\s/g, '');
      this.ngControl.control.setValue(finalValue);
    } catch (ex) {
      this.ngControl.control.setValue(null);
    }
  }

}
