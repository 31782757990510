import { LanguageVersion } from '@data/common/LanguageVersion';
import { MenuItem } from '@data/common/MenuItem';

export namespace ApplicationActions {
  const prefix = '[Application]';

  export class ChangeLanguage {
    static readonly type = `${ prefix } Change language`;

    constructor(public language: LanguageVersion) {

    }
  }

  export class UpdateMenu {
    static readonly type = `${ prefix } Initialize menu`;

    constructor(public menu: MenuItem[]) {

    }
  }
}