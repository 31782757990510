import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[appInputNumberOnly]' })
export class InputNumberOnlyDirective {

  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, dashboard
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Delete' ];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', [ '$event' ])
  public onKeyDown(event: KeyboardEvent): void {
    // Allow Backspace, tab, end, and dashboard keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
