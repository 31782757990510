import { Component, Input } from '@angular/core';
import { AccountDTO } from '@data/auth/AccountDTO';
import { MenuItem } from '@data/common/MenuItem';
import { Actions, Select, Store } from '@ngxs/store';
import { ApplicationSelectors } from '@shared/storage/application/application.selectors';
import { AuthActions } from '@shared/storage/auth/auth.actions';
import { AuthSelectors } from '@shared/storage/auth/auth.selectors';
import { ApplicationModel } from 'app/model/application.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: [ './side-menu.component.scss' ]
})
export class SideMenuComponent {
  @Select(AuthSelectors.getCurrentLogged)
  public user$: Observable<AccountDTO>;
  @Select(ApplicationSelectors.menuItems)
  public menuItems$: Observable<MenuItem[]>;

  @Input() public menuCollapse: boolean = false;

  constructor(public readonly applicationModel: ApplicationModel, private store: Store, private actions: Actions) {
  }

  public logout(): void {
    this.store.dispatch(new AuthActions.Logout());
  }

}
