import { Inject, Injectable } from '@angular/core';
import { HttpUtil } from '@shared/util/http.util';
import { Observable } from 'rxjs';
import { TokenDTO } from '@data/auth/TokenDTO';
import { RequestOptions } from '@data/interface/RequestOptions';
import { AccountDTO } from '@data/auth/AccountDTO';
import { ApplicationConfig } from '@config/application.config';
import { map } from 'rxjs/operators';
import { AuthProvider } from '@data/auth/AuthProvider';
import { AUTH_PROVIDER } from '@shared/common/injector-token';
import { ObjectUtil } from '@shared/util/object.util';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpUtil: HttpUtil,
              @Inject(AUTH_PROVIDER) private authProvider: AuthProvider) {
  }

  public login(username: string, password: string): Observable<TokenDTO> {
    return this.authProvider.login(username, password);
  }

  public setPassword(username: string, newPassword: string): Observable<TokenDTO> {
    return this.authProvider.setPassword(username, newPassword);
  }

  public forgetPassword(username: string): Observable<void> {
    return this.authProvider.forgotPassword(username);
  }

  public resendVerificationCode(username: string): Observable<void> {
    return this.authProvider.forgotPassword(username);
  }

  public confirmPassword(username: string, verificationCode: string, newPassword: string): Observable<void> {
    return this.authProvider.confirmPassword(username, verificationCode, newPassword);
  }

  public getCurrentLoggedUser(): Observable<AccountDTO> {
    const options = { silentMode: true } as RequestOptions;

    return this.httpUtil.get(`${ ApplicationConfig.apiUrl }/users/me`, undefined, options)
      .pipe(
        map((response: AccountDTO) => ObjectUtil.plainToInstance(AccountDTO, response))
      );
  }

  public refreshToken(token: TokenDTO): Observable<TokenDTO> {
    return this.authProvider.refresh(token);
  }

}
