import { AfterContentInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appHideIfElementEmpty]'
})
export class HideIfElementEmptyDirective implements AfterContentInit, OnDestroy {
  @Input() appHideIfElementEmpty: string;

  private readonly element: HTMLElement;

  private elementToCompare: Element;

  private mutationObserver: MutationObserver;

  private elementBaseDisplayValue: string;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngAfterContentInit(): void {
    this.elementToCompare = this.element.querySelector(this.appHideIfElementEmpty);
    this.elementBaseDisplayValue = (this.element.style.display !== 'none' && this.element.style.display !== '') ? this.element.style.display : 'initial';

    this.checkAndHideElement();
    this.observeElementToCompareChanges();
  }

  private checkAndHideElement(): void {
    if (this.elementToCompare?.children?.length === 0) {
      this.element.style.display = 'none';
    } else {
      this.element.style.display = this.elementBaseDisplayValue;
    }
  }

  private observeElementToCompareChanges(): void {
    if (!this.elementToCompare) {
      return;
    }

    this.mutationObserver = new MutationObserver(() => {
      this.checkAndHideElement();
    });

    this.mutationObserver.observe(this.elementToCompare, {
      childList: true,
      subtree: true
    });
  }

  ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
}
