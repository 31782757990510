import { Exclude, Type } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';

export abstract class IdentifiableObject {
  @Type(() => Number)
  public id: number;

  @Exclude({ toPlainOnly: true })
  public _uuid?: string = uuidv4();
}
