import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-read-only-entry',
  templateUrl: './read-only-entry.component.html',
  styleUrls: [ './read-only-entry.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadOnlyEntryComponent {
  @Input() label: string;

  @Input() value: string;

  @Input() setClass: string;
}
