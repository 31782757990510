<div class="element" [ngClass]="setClass">
  <span class="title">{{ label | translate }}</span>
  <span class="value">
    <span class="content-value" #contentValue><ng-content></ng-content></span>
    <span class="raw-value" [hidden]="contentValue.hasChildNodes()">
    {{ value | replaceEmpty }}
    </span>

  </span>
</div>
