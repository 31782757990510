import { Selector } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from './application.state';

export class ApplicationSelectors {
  @Selector([ ApplicationState ])
  static language(state: ApplicationStateModel) {
    return state.language;
  }

  @Selector([ ApplicationState ])
  static menuItems(state: ApplicationStateModel) {
    return state.menu;
  }
}