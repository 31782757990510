import { Pipe, PipeTransform } from '@angular/core';
import { FullName } from '@data/interface/FullName';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(value: FullName): string {
    return value?.firstName ? `${ value.firstName } ${ value?.lastName }` : '-';
  }

}
