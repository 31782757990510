import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inArray'
})
export class InArrayPipe implements PipeTransform {

  transform(value: any[], key: any): boolean {
    return (value || []).includes(key);
  }

}
